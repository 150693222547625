import React from "react"
//Components
import Layout from "../components/layout"
// bootstrap ----------------------
import { Col, Container, Row, Form, Button } from 'react-bootstrap';
// css ---------------------------
import { css } from '@emotion/react';
import styled from '@emotion/styled';
// images -------------------------
import imgBannerRadial from './../../static/backgroundBanner.png'

const Contact = () => {


    const Radial = styled.section`
        background-image: url(${imgBannerRadial}); /* The image used */
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        @media (max-width:992px){
            .containerForm{
                width:100% !important;
            }
        }
        @media (max-width:768px){
            .containerForm{
                width:85% !important;
            }
        }
        @media (max-width:480px){
            .containerForm{
                width:90% !important;
            }
        }
    `;

    const TitleAboutUs = styled.h2`
		font-size:3rem;
		color:#293594;
		font-weight:bold;
		@media (max-width: 768px){
			text-align:center !important;
		}
	`;
    const InputCss = styled(Form.Control)`
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius:0;
        border:0;
    `;
    const InputCssLabel = styled(Form.Label)`
        font-family: Open Sans;
        font-weight: 600;
        font-size: 1.2rem;
        color: #0050E9;

    `;
    const InfoContact = styled(Form.Label)`
        font-family: Open Sans;  
        color: #000000;
        font-size:1.2rem;
    `;
    return (
        <Layout>
            <Radial>
                <Container fluid css={css`
                    padding-top:10rem;
                    @media (max-width:992px){
                        padding-top:4rem;
                    }
                `}>
                    <Row className="d-flex align-items-center justify-content-center">
                        <Col xl={6} lg={8} md={10} sm={12} className="pl-3 pl-md-0 mb-5 d-none d-xl-block">
                            <img src={'/woman_contact.jpg'} className="w-100 " alt="contact" />
                        </Col>
                        <Col xl={6} lg={8} md={10} sm={12} className="mb-5 mt-5 mt-xl-0">
                            <div className="w-75 m-auto containerForm">
                            <TitleAboutUs className="text-center text-lg-left">Contáctanos</TitleAboutUs>
                            <InfoContact className="my-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras consectetur tempor felis.</InfoContact>
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridEmail">
                                        <InputCssLabel>Nombre</InputCssLabel>
                                        <InputCss type="text" />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formGridPassword">
                                        <InputCssLabel>Apellido</InputCssLabel>
                                        <InputCss type="text" p />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Group controlId="formGridAddress1">
                                    <InputCssLabel>Email</InputCssLabel>
                                    <InputCss type="email" />
                                </Form.Group>

                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <InputCssLabel>Mensaje</InputCssLabel>
                                    <InputCss as="textarea" rows={3} />
                                </Form.Group>



                                <Button variant="primary" type="submit" className="border-radius-20 px-5">
                                    ENVIAR
                                </Button>
                            </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Radial>
        </Layout>
    )
}

export default Contact
